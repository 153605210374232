import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Menu from './Menu'
import Footer from './Footer'
import '../../stylesheets/main.scss'
import logo from '../../images/logo.png'

export default props => (
  <StaticQuery
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={ data => <Layout data={ data } { ...props }/> }
  />
)

const Layout = ( { children, data, title = 'Home' } ) => {
  // Define the meta title and description
  const siteTitle = data.site.siteMetadata.title
  const description = data.site.siteMetadata.description

  // Load the Prismic edit button
  if(typeof window !== 'undefined' && window.prismic) {
    window.prismic.setupEditButton()
  }

	return(
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ `${siteTitle} - ${title}` }</title>
        <meta name="description" content={ description } />
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
        <link rel="stylesheet" href="https://unpkg.com/purecss@1.0.1/build/pure-min.css" integrity="sha384-oAOxQR6DkCoMliIh8yFnu25d7Eq/PHS21PClpwjOTeU2jRSq11vu66rf90/cZr47" crossorigin="anonymous" />
        <link rel="stylesheet" href="https://unpkg.com/purecss@1.0.1/build/grids-responsive-min.css" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="https://use.typekit.net/ugw7bqk.css" />
        <link rel="stylesheet" href="/styles.css" />
        <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v8.0&appId=602431919775263" nonce="Wz98BjtE"></script>
        <script src="https://cdn.usefathom.com/script.js" data-spa="auto" data-site="CTAVSZDC" defer></script>
      </Helmet>
      <div className="nav pure-g">
        <div className="pure-u-xl-1-5 pure-u-2-5">
          <a href="/">
            <img src={ logo } alt="FMMAA" className="logo" />
          </a>
        </div>
        <Menu />
      </div>
      <div className="content">
        { children }
        <Footer/>
      </div>
      <div id="fb-root"></div>
    </Fragment>
	)
}
