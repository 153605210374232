import React from 'react'

export default () =>
  <div class="footer">
    <div class="terms">
      <div class="fb-like" data-href="https://www.facebook.com/fourmarksmartialartsacademy" data-width="" data-layout="button_count" data-action="like" data-size="large" data-share="true"></div>
    </div>
    <div class="copyright">
      © Four Marks Martial Arts Academy Ltd {new Date().getFullYear()} | <a href="/contact">Contact Us</a>
    </div>
  </div>
