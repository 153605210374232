import React, { useState } from 'react'
import menu from '../../images/menu.png'

export default () => {
  const [ menuOpen, setMenuOpen ] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }
  
  const renderLinks = (mobile) => (
    <ul className={`links ${mobile ? 'mobile' : ''}`}>
      <li>
        <a href="/news">News</a>
      </li>
      {!mobile && (
      <li style={{margin: 0}}>
        •
      </li>
      )}
      <li>
        <a href="/about">About</a>
      </li>
      {!mobile && (
      <li style={{margin: 0}}>
        •
      </li>
      )}
      <li>
        <a href="/classes">Classes</a>
      </li>
    </ul>
  );

  return (
    <>
      <div class="menu-container pure-u-3-5 pure-u-xl-hidden">
        <a href="#" className={`toggle-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <img src={ menu } alt="Menu" />
        </a>
        <div class="menu">
          {menuOpen && renderLinks(true)}
        </div>
      </div>
      <div class="pure-u-hidden pure-u-xl-4-5 pure-u-xl-visible">
        {renderLinks(false)}
      </div>
    </>
  );
};
